section.footer-sitemap {
  padding: rem-calc(50) 0 rem-calc(65) 0;
  @include media-breakpoint-down(sm) {
    .site-logo-button {
      margin-bottom: rem-calc(32);
    }
  }
  nav {
    padding-bottom: rem-calc(32);
    a {
      color: black;
      display: inline-block;
    }
    > a {
      @include body(xs);
      padding-bottom: rem-calc(20);
    }
    ul {
      li {
        a {
          @include body(xs);
          letter-spacing: rem-calc(0.16);
          padding-bottom: rem-calc(8);
        }
      }
    }
  }
}

.up-to-top {
  display: table;
  padding: rem-calc(7);
  background: blue;
  cursor: pointer;
  svg {
    fill: white;
  }
}
