.ReactModal__Content {
  padding: 0 !important;
  border-radius: 0 !important;
  border: 2px solid $black !important;

  .modal-header {
    border-radius: 0;
    display: flex;
    background-color: $fond-color;
    padding: 0;
    position: relative;
    height: rem-calc(60);
    border: none;

    .modal-actions {
      button {
        padding: rem-calc(15);
        width: rem-calc(60);
        height: rem-calc(60);
        border-radius: 0;

        &:hover {
          background-color: $black;
          img {
            filter: invert(1);
          }
        }

        &:focus {
          outline: none;
        }
      }

      &.modal-actions-left button {
        border-right: 2px solid $black;
      }

      &.modal-actions-right button {
        border-left: 2px solid $black;
      }
    }

    .modal-title {
      display: flex;
      justify-content: start;
      align-items: center;
      @include body(m);
      align-self: center;
      width: 100%;
      height: rem-calc(60);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
    }
  }

  .modal-body {
    border-radius: 0;
    max-height: 50vh;
    overflow-y: auto;
    padding: rem-calc(15);
    border-top: 2px solid $black;
  }
}
