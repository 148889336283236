table {
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid $black;
    }

    td {
      @include body(s);
      padding: rem-calc(10) 0;

      &:not(:last-child) {
        padding-right: rem-calc(30);
      }
    }
  }

  @include media-breakpoint-down(md) {
    tr {
      display: flex;
      flex-wrap: wrap;
      padding: rem-calc(10) 0;

      td {
        padding: 0;

        &:not(:last-child) {
          padding-right: rem-calc(15);
        }

        &.full-width {
          flex-basis: 100%;
        }
      }
    }
  }
}