button {
  background: transparent;
  box-shadow: none;
  border: none;
  font-weight: 600;

  &:focus {
    outline: none;
  }
}

@mixin button-active() {
  background: $dark !important;
  color: $white !important;
  border-color: $dark !important;
  svg {
    fill: $white;
  }
}

.btn {
  padding: rem-calc(5) rem-calc(15);
  // min-width: rem-calc(170);
  cursor: pointer;
  text-align: center;
  color: inherit !important;
  border: 2px solid $dark;
  font-weight: 600;
  background: transparent;

  &:not(:last-child) {
    margin-right: rem-calc(15)
  }
  * {
    color: inherit !important;
  }
  svg {
    transition: 0.3s fill;
  }


  &.active {
    @include button-active();
  }
  &:hover {
    @include media-breakpoint-up(lg) {
      @include button-active();
    }
  }

  &.btn-primary {
    border-radius: rem-calc(5);
  }

  &.btn-secondary {
    border-radius: rem-calc(30);
  }
  &:focus {
    box-shadow: none !important;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: $primary-color-active;
  }
}