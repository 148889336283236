section.paragraph.paragraph-akkordion-text,
.paragraph-audio,
section.paragraph.paragraph-extended-teaser-overview.type-person {
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(30);

  .accordion__item {
    border-top: rem-calc(2) solid black;
    &:last-child {
      border-bottom: rem-calc(2) solid black;
    }
    .accordion__heading {
      //@include body(s);
      padding: rem-calc(12) 0 rem-calc(12) 0;
      cursor: pointer;
      position: relative;
    }
    .accordion__button {
      outline: none;
      &:focus,
      &:focus-visible {
        outline: none;
      }
      &:hover {
        .icon {
          @include media-breakpoint-up(lg) {
            transform: rotate(180deg);
          }
        }
      }
      .inktrap {
        @include media-breakpoint-down(sm) {
          font-size: 1.25rem;
        }
      }
    }

    .accordion-panel-wrapper {
      padding: 0;
      display: flex;

      &.image-left {
        flex-direction: column;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }
      }

      &.image-top {
        flex-direction: column;
      }

      .image-wrapper {
        margin-bottom: rem-calc(15);

        @include media-breakpoint-up(sm) {
          margin-right: rem-calc(30);
          flex-basis: 200px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        @include media-breakpoint-up(md) {
          flex-basis: 300px;
        }
      }

      .text-wrapper {
        .text {
          padding-bottom: $whitespace;
        }

        .contact-details {
          margin: rem-calc(15) 0;

          span {
            @include body(s);
          }

          p {
            margin-bottom: rem-calc(5);
            @include body(xs);
            span {
              @include body(xs);
            }
            &::before, span::before {
              font-weight: 800;
              display: inline;
            }

            a {
              color: $black;
              text-decoration: underline;
            }
          }

          .phone::before {
            content: "Telefon ";
          }

          .mobile::before {
            content: "Mobil ";
          }

          .mail::before {
            content: "E-Mail ";
          }

          .department-value::before {
            content: "Bereich ";
          }
        }
      }
    }

    h3.main-title,
    h4.sub-title {
      margin-bottom: 0;
    }
    h3.main-title {
      @include headline(m);
      max-width: 92.5%;
    }
    .text h3 {
      @include body(m);
    }
    .sub-title {
      @include body(s);
      margin-top: rem-calc(5);
    }
    .icon {
      position: absolute;
      top: rem-calc(12);
      right: 0;
      transition: 250ms transform;
    }
  }
}

.accordion__button[aria-expanded="true"] {
  .icon {
    transform: rotate(180deg);
  }
}
