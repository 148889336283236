.teaser-wrapper {
  margin-bottom: rem-calc(20);

  @include media-breakpoint-up(md) {
    flex: 0 50%;
    max-width: 50%;
  }
  @include media-breakpoint-up(lg) {
    flex: 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  a {
    color: inherit;
  }
  .inner-wrapper {
    background: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    > div {
      &:first-child {
        &.info-wrapper {
          padding-top: rem-calc(15);
        }
      }
    }
    .info-wrapper {
      padding: rem-calc(15);

      h3 {
        margin-bottom: rem-calc(15);
        @include body(m);
      }

      .teaser-text, .teaser-text * {
        @include body(s);
      }

      &.overflow .teaser-text::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, white, transparent);
      }
    }
  }
}
