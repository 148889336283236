section.paragraph.paragraph-strukturierte-liste {
  padding: 0;
  h3 {
    @include headline(xl);
    line-height: 1;
    margin-top: calc(#{$whitespace});
    margin-bottom: calc(#{$whitespace / 2});
  }
  .container {
    max-width: 100%;
    .list-item {
      padding-top: rem-calc(18);
      padding-bottom: rem-calc(18);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
      @include media-breakpoint-up(lg) {
        padding-top: rem-calc(28);
        padding-bottom: rem-calc(18);
        padding-left: rem-calc(30);
        padding-right: rem-calc(30);
      }
      &:nth-child(odd) {
        background: $lime;
      }
      .text, .text * {
        @include headline(l);
      }
    }
  }
}
