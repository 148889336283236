section.paragraph-calendar-information {
  span.duration {
    padding-left: rem-calc(10);
  }
  a.btn {
    display: inline-table;
    width: auto;
    min-width: auto;
    padding-right: rem-calc(50);
    margin-right: rem-calc(20);
    &:last-child {
      margin-right: 0;
    }
  }
}
