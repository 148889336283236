.paragraph.webform-paragraph .webform-container {
  position: relative;

  .webform-composite-address {
    .street-address {
      .form-group:first-child {
        margin-bottom: 0;
      }
    }
  }

  .webform-details {
    padding: 0 1em 0 1em;

    &[open] {
      padding-bottom: 1em;
    }

    summary {
      padding: 1em 0;
      font-size: 1.25em;
    }

    border: rem-calc(1) solid $greyish;
  }

  .webform-fieldset {
    padding: rem-calc(16);
    border: rem-calc(1) solid $greyish;

    legend {
      display: inline-block;
      width: auto;
      padding: 0 0.5em;
    }
  }

  .webform-flexbox {
    .flexbox-wrap {
      display: flex;

      > * {
        min-width: 0;
        flex: 1;
        margin: 0 1em;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .form-group.form-check + .form-group.form-check {
    margin-top: -1.25em;
  }

  h2 {
    @include headline(l);
    margin-bottom: calc(#{$whitespace} / 2);
  }

  h3 {
    @include body(m);
    margin-bottom: calc(#{$whitespace} / 2);
  }

  p:last-child {
    margin-bottom: calc(#{$whitespace} * 2)
  }

  @include body(xs);

  label {
    .required {
      color: red;
    }
  }

  .error-message {
    color: red;
  }

  .loading-indicator-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(50, 50, 50, 0.15);
  }

  form {
    .form-description {
      color: $black-two !important;
      font-size: 100%;
    }

    .form-check {
      display: flex;
      flex-flow: column;
      margin-right: rem-calc(16);
      justify-content: center;
      padding-bottom: rem-calc(10);
      @include media-breakpoint-down(md) {
        margin-bottom: rem-calc(8);
      }

      input[type="checkbox"]:before {
        position: relative;
      }

      label {
        display: inline-block !important;
        margin-top: rem-calc(3) !important;
        @include body(xs);
      }
    }

    input[type="file"] {
      padding: 0;
      height: auto;
    }

    .markup {
      p:last-child {
        margin-bottom: 1rem;
      }
    }

    .form-group.custom-composite {
      label {
        h3 {
          margin-bottom: 0;
        }
      }

      label + .form-row {
        margin-top: 1rem;
      }

      .form-description {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }

  label {
    @include body(xs);
  }

  .markup.hidden {
    display: none;
  }

  .form-group {
    &.hidden {
      display: none;
    }

    margin-bottom: 1.25rem;

    .error-message {
      @include body(xxxs);
      margin-top: rem-calc(4);
      display: block;
    }
  }
}
