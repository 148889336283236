.info-box {
  position: relative;
  padding-top: rem-calc(10);
  /*&:before {
    content: "";
    width: calc(100% - 32px);
    position: absolute;
    top: 0;
    left: 16px;
    height: 1px;
    background: $dark;
  }*/
  .meta-wrapper {
    @include body(xs);
    gap: 4px;
    display: flex;
    margin-bottom: calc(#{$whitespace} / 3);
  }
  h3 {
    @include headline(l);
    margin-bottom: rem-calc(4);
  }
  .text, .text * {
    @include body(m);
  }
  .link-wrapper {
    margin-top: rem-calc(10);
    a {
      @include body(xs);
      border-bottom: rem-calc(1) solid $dark;
      color: inherit;
      margin-right: rem-calc(5);
      text-decoration: none;
    }
    .inktrap {
      @include body(xs);
      font-family: "area-inktrap", sans-serif;
      font-weight: 700;
    }
  }


  .counter {
    @include headline(l);
    text-align: right;
    order: -1;
    margin-bottom: rem-calc(15);
    padding-right: 0;
    @include media-breakpoint-up(lg) {
      order: 1;
      text-align: right;
      margin-bottom: 0;
      @include headline(m);
    }
    @include media-breakpoint-up(xl) {
      @include headline(l);
    }
  }
}