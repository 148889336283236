/**
 * Teaser Views modes.
 */
article.node.node-teaser.teaser-projekt {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: white;
  img {
    margin: 0;
  }
  h3 {
    @include body(m);
    margin-bottom: rem-calc(5);
  }
  .text-wrap {
    padding: rem-calc(30);
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      * {
        text-decoration: none;
      }
      .image-wrapper {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .image-wrapper {
    overflow: hidden;
    img {
      transition: 2500ms transform;
    }
  }
}
