section.paragraph.paragraph-zitat {
  blockquote {
    @include headline(l);
    cite {
      padding-top: rem-calc(30);
      display: block;
      @include body(s);
    }
  }
}
