@keyframes bot-bubble-appear {
  0% {
    left: -100%;
  }
  90% {
    left: 1%;
  }
  100% {
    left: 0;
  }
}

@keyframes user-bubble-appear {
  0% {
    right: -100%;
  }
  90% {
    right: 1%;
  }
  100% {
    right: 0;
  }
}

@keyframes option-bubble-appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate-hover {
  0% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0);
  }
}

.eforkbot {
  .rsc-ts-bubble,
  .rsc-cs {
    transition: 500ms all;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .rsc-ts-bot {
    .rsc-ts-bubble {
      left: -100%;
      animation: bot-bubble-appear 500ms ease-in-out forwards !important;
    }
  }
  .rsc-ts-user {
    .rsc-ts-bubble {
      right: -100%;
      animation: user-bubble-appear 500ms ease-in-out forwards !important;
    }
  }
  .rsc-cs {
    right: -100%;
    position: relative;
    animation: user-bubble-appear 500ms ease-in-out forwards !important;
  }
  .rsc-os-options {
    .rsc-os-option {
      animation: option-bubble-appear 500ms ease-in-out forwards !important;
      .rsc-os-option-element {
        transition: 500ms all;
        &:hover {
          animation: rotate-hover 250ms ease-in-out !important;
        }
      }
    }
  }
}

.eforkbot {
  .rsc-header {
    display: none;
  }
  .rsc-container {
    background: transparent;
    height: auto !important;
    min-height: 100vh;
    box-shadow: none;
    transition: 500ms height;
    * {
      box-shadow: none !important;
    }
  }
  .rsc-content {
    overflow: auto;
    overflow-x: hidden !important;
    height: auto !important;
    min-height: 100vh;
    padding: rem-calc(20);
    transition: 500ms height;
  }
  // Options
  .rsc-os {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .rsc-os-options {
    align-items: flex-end;
    display: flex;
    .rsc-os-option-element {
      background: gray;
      font-size: rem-calc(24);
      padding: rem-calc(12) rem-calc(24);
    }
  }
  // Bot Stylings
  .rsc-ts-bot {
    .rsc-ts-image-container {
      display: none;
    }
    .rsc-ts-bubble {
      margin-left: rem-calc(10);
      font-size: rem-calc(34);
      transition: 250ms background-color;
      padding: rem-calc(20) rem-calc(30);
      &:before {
        content: "";
        transition: 250ms all;
      }
    }
  }
  // User Stylings
  .rsc-ts-user {
    .rsc-ts-image-container {
      display: none;
    }
    .rsc-ts-bubble {
      margin-right: rem-calc(10);
      background: lightgray;
      font-size: rem-calc(24);
      padding: rem-calc(24) rem-calc(24);
    }
  }
  .choose-a-style {
    width: 100%;
    .question {
      font-size: rem-calc(50);
      font-weight: bold;
    }
    .answer {
      font-size: rem-calc(34);
      cursor: pointer;
      transition: 250ms background-color;
      padding: rem-calc(20) rem-calc(30);
      z-index: 1;
      &:before {
        content: "";
        transition: 250ms all;
      }
      transition: 500ms all;
      &:hover {
        color: blue;
        animation: rotate-hover 250ms ease-in-out !important;
      }
      font-size: rem-calc(34);
      cursor: pointer;
      background: yellow;
      border-radius: rem-calc(24);
      margin: 0 rem-calc(10);
      padding: rem-calc(14);
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center;
      .option {
        text-align: center;
      }
    }
  }
}

// Crazy Bot Styling
.eforkbot[data-style="different"] {
  .rsc-ts-bubble,
  .answer {
    background: transparent;
    position: relative;
    overflow: visible;
    font-style: italic;
    color: white;
    &:before {
      content: "";
      -webkit-filter: blur(rem-calc(5));
      filter: blur(rem-calc(5));
      position: absolute;
      height: 100%;
      width: 100%;
      background: #6e48aa;
      border-radius: 0 rem-calc(18) rem-calc(18) 0;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .answer {
    color: black;
    &:before {
      content: "";
      background: yellow;
    }
  }
  .rsc-ts-user .rsc-ts-bubble {
    color: black;
    &:before {
      content: "";
      background: lightgray !important;
    }
  }
}

// Clear Bot Styling
.eforkbot[data-style="clear"] {
  .rsc-ts-bubble {
    background: transparent;
    position: relative;
    overflow: visible;
    color: black;
  }
  .answer {
    background: lightgray;
    &:hover {
      color: aliceblue;
    }
  }
}

.video-wrapper {
  width: 100%;
  video {
    width: 100%;
    position: relative;
    display: block;
  }
}

.no-pointers {
  pointer-events: none;
}

.showcased-links {
  .link {
    margin-right: 0 !important;
    .rsc-ts-bubble {
      margin-bottom: rem-calc(40);
    }
  }
  .image-wrapper {
    padding: rem-calc(20) rem-calc(10);
  }
}

.audio-wrapper {
  width: 100%;
  display: block;
  position: relative;
  .col-16 {
    display: block;
  }
  audio {
    width: rem-calc(500);
    display: block;
    position: relative;
    max-width: 100%;
  }
}

.rsc-footer {
  display: none;
}
