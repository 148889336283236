/**
 * Teaser Views modes.
 */
article.teaser-news.teaser-news-timeline,
article.teaser-news.teaser-news-timeline-list {
  .tag {
    background-color: $white-three;
    color: $greyish;
    padding: rem-calc(2) rem-calc(6);
    @include body(xs);
    margin-right: rem-calc(10);
    border-radius: rem-calc(12);

    &:before {
      content: "#";
    }
  }

  h3 {
    @include body(m);
    margin-top: rem-calc(20);
  }

  &.teaser-news.teaser-news-timeline-list {
    h3 {
      margin-top: 0;
    }

    img {
      margin: 0;
    }
  }
}
