form {
  .form-check {
    padding-left: rem-calc(32) !important;

    .form-check-input {
    }

    label {
      @include body(xs);
      padding-left: rem-calc(4) !important;
    }
  }

  label {
    color: $black-two;
    @include body(xs);
  }

  input[type="text"],
  input[type="email"] {
    background: transparent;
    height: rem-calc(40);
    padding: rem-calc(12) rem-calc(15) rem-calc(10) rem-calc(15);
    color: $dark;
    @include body(xs);
    border-radius: rem-calc(24);
    border: rem-calc(2) solid $dark;
  }

  input[type="checkbox"] {
    margin-right: rem-calc(10);
  }

  .form-error {
    display: block;
    margin-bottom: rem-calc(-10);
  }

  button[type="submit"] {
    margin-top: rem-calc(40);
  }

  label {
    color: $dark;
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 0;
    @include media-breakpoint-down(md) {
      width: rem-calc(8);
      height: rem-calc(8);
      margin-top: rem-calc(6);
      border: none !important;
    }

    &:before {
      content: "";
      background: $purple;
      display: block;
      border: rem-calc(2) solid;
      cursor: pointer;
      margin-left: rem-calc(-2);
      margin-top: rem-calc(-6);
      width: rem-calc(16);
      height: rem-calc(16);

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &:checked {
      &:after {
        content: "";
        background: url("/checkmark.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: rem-calc(10);
        height: rem-calc(10);
        top: rem-calc(-2);
        left: rem-calc(1);
        display: block;
        position: absolute;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
          top: rem-calc(4);
        }
      }
    }
  }
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark !important;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $dark !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: $dark !important;
}
