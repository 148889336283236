section.paragraph.paragraph-hero-text {
  .hero.text {
    p {
      @include body(xl);
    }
    a {
      color: $body-color;
      text-decoration: none;
      white-space: nowrap;
      &:before {
        content: "";
        height: rem-calc(35);
        background: url("/carbon-icons/arrow--down-right.svg");
        background-repeat: no-repeat;
        width: rem-calc(35);
        background-size: contain;
        display: inline-block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.hint {
  color: white;
  background: $primary-color-active;
  padding: rem-calc(16) 0 rem-calc(24) 0;
  p {
    margin: 0;
    a {
      color: white;
      text-decoration: none;
      white-space: nowrap;
      &:before {
        content: "";
        height: rem-calc(17);
        width: rem-calc(17);
        background: url("/carbon-icons/arrow--down-right--white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-bottom: rem-calc(-3);
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
