section.paragraph.paragraph-teaser-slider {
  background-color: $white-two;
  padding: 0;
  .slick-slider {
    @include media-breakpoint-down(lg) {
      .slick-arrow {
        top: auto;
        bottom: 5%;
      }
    }
    .slick-slide {
      @include media-breakpoint-up(md) {
        padding: $paragraph-section-padding;
      }
      position: relative;
      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        object-position: center;
      }
      article.container {
        padding: rem-calc(15) 0 25% 0;
        @include media-breakpoint-up(md) {
          min-height: 60vh;
          padding: rem-calc(60) rem-calc(30);
        }
        position: relative;
        h3 {
          @include headline(l);
          margin-bottom: rem-calc(15);
          @include media-breakpoint-up(md) {
            max-width: 60%;
            margin-bottom: rem-calc(65);
          }
        }
        p.pager {
          @include body(xs);
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
