// Bootstrap Breakpoints.
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-columns: 10;
$grid-gutter-width: 1.875rem;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone -> 576px
  sm: 36rem,
  // Medium screen / tablet -> 768px
  md: 48rem,
  // Large screen / desktop -> 1200px
  lg: 75rem,
  // Extra large screen / wide desktop -> 1920px
  xl: 120rem
);

$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
);

// Colors.
$black: #161616;
$black-two: #393939;
$body-color: $black;
$body-bg: white;

// Theme colors.
$greyish: #a8a8a8;
$primary-color: #0353e9;
$primary-color-active: #0f62fe80;
$pale-sky-blue: #d0e2ff;
$dark: #121619;
$pale-grey: #dde1e6;
$white: #f4f4f4;
$white-two: #e0e0e0;
$white-three: #f3f3f3;
$pinkish-grey: #c6c6c6;

// JKS Colors
$lilac: #ECD0F6;
$yellow: #FCFF52;
$blue: #0093D6;
$red-orange: #FF6240;
$brown: #A16461;
$lime: #BDFF7B;
$purple: #C0C0FF;
$green-1: #A6E8B8;
$green-2: #009180;
$dark-blue: #332c81;
$light-grey: #d0d3d0;

$fond-color: #DEDEDE;

// Theme paddings and margins.
$paragraph-section-padding: rem-calc(30) 0;
$paragraph-section-padding-mobile: rem-calc(15) 0;
$whitespace: rem-calc(40);
