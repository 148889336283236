@keyframes detail-wrapper-appear {
  0% {
    right: -50vw;
  }
  90% {
    right: 1vw;
  }
  100% {
    right: 0vw;
  }
}

.cloud-detail-overlay {
  position: relative;
  z-index: 100;
  .cloud-detail-inner {
    position: fixed;
    top: 0;
    right: -52vw;
    width: 52vw;
    height: 100vh;
    background: white;
    box-shadow: rem-calc(-1) 0 rem-calc(4) 0 rgba(0, 0, 0, 0.33);
    animation: detail-wrapper-appear 250ms ease-in-out forwards !important;
    .cloud-detail-content {
      padding: calc(#{$whitespace} * 2);
      -webkit-overflow-scrolling: touch;
      overflow: overlay;
      height: 100vh;
      .content-wrap {
        .container {
          max-width: 100%;
          padding: 0;
        }
        .paragraph-text,
        .paragraph-teaser-image,
        .paragraph-click-suggestions,
        .paragraph-akkordion-text,
        .paragraph-zitat,
        .paragraph-download-schublade,
        .paragraph-text-and-speech {
          .col-wrapper,
          .col-16,
          .col-12,
          .text-container {
            margin: 0;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .paragraph-teaser-image {
          h3 {
            margin-top: $whitespace;
          }
          .text {
            margin: 0;
          }
        }
      }
    }
  }
}

.cloud-detail-go-back {
  position: fixed;
  bottom: rem-calc(30);
  left: rem-calc(30);
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: 500ms opacity;
  > * {
    width: rem-calc(50);
    height: rem-calc(50);
  }
}

.cloud-teaser {
  &.active {
    .cloud-detail-go-back {
      opacity: 1;
      pointer-events: all;
    }
  }
}
