@keyframes shake-1 {
  0% { transform: translate(1%, 1%) rotate(0deg) scale(1); }
  25% { transform: translate(8%, 8%) rotate(2deg) scale(1.1); }
  50% { transform: translate(-2%, -2%) rotate(4deg) scale(1); }
  75% { transform: translate(-10%, 8%) rotate(0deg) scale(1.1); }
  100% { transform: translate(1%, 1%) rotate(0deg) scale(1); }
}

@keyframes shake-2 {
  0% { transform: translate(2%, 2%) rotate(0deg) scale(1); }
  25% { transform: translate(0, 0) rotate(-2deg) scale(0.9); }
  50% { transform: translate(-2%, 2%) rotate(0deg) scale(1); }
  75% { transform: translate(4%, 2%) rotate(0deg) scale(1.1); }
  100% { transform: translate(2%, 2%) rotate(0deg) scale(1); }
}

@keyframes shake-3 {
  0% { transform: translate(-4%, -4%) rotate(0deg) scale(0.9); }
  25% { transform: translate(4%, 4%) rotate(1deg) scale(1); }
  50% { transform: translate(-2%, 4%) rotate(1deg) scale(0.9); }
  75% { transform: translate(3%, 2%) rotate(0deg) scale(1); }
  100% { transform: translate(-4%, -4%) rotate(0deg) scale(0.9); }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}