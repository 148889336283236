section.paragraph-teaser-image {
  h3 {
    @include headline(l);
  }
  .text {
    margin: 0 0 rem-calc(60) 0;
    p, * {
      @include body(m);
      margin: 0;
    }
    ul, ol {
      li, li * {
        @include body(m);
      }
    }
  }
  img {
    @include media-breakpoint-down(md) {
      margin-bottom: rem-calc(15);
    }
  }
  .read-more {
    display: inline-flex;
    align-items: center;
    a {
      color: inherit;
      border-bottom: 1px solid;
      margin-right: rem-calc(12);
      padding-bottom: rem-calc(4);
      &:hover {
        text-decoration: none;
      }
    }
    .inktrap {
      line-height: 1;
    }
  }
}
