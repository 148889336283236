#pageFooter {
  background: $purple;
  padding-bottom: rem-calc(30);
  padding-top: rem-calc(60);

  @include media-breakpoint-up(md) {
    padding-top: rem-calc(100);
  }

  .social-media {
    a {
      display: inline-block;
      width: rem-calc(30);
      height: rem-calc(30);
      margin-right: rem-calc(50);
      @include media-breakpoint-down(sm) {
        margin-bottom: rem-calc(20);
      }
    }
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .footer-menu-wrapper {
      flex-basis: 100%;
    }

    .jks-logo-wrapper {
      flex-basis: 130px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      align-items: start;

      .jks-logo-wrapper {
        align-self: start;
        margin-top: rem-calc(60);
      }
    }

    nav.footer-menu {
      ul {
        display: flex;
        flex-flow: wrap;
        margin-bottom: rem-calc(30);
        li {
          margin-right: rem-calc(30);
          @include body(xs);
          a {
            color: $black;
          }
        }
      }
    }
  }

  /*
  .footer {
    @include body(xs);
    .footer-menu {
      margin-bottom: rem-calc(32);
      ul {
        li {
          margin-right: rem-calc(8);
          a {
            display: flex;
            align-items: center;
            color: $black;
            svg {
              width: rem-calc(25);
              margin-right: rem-calc(5);
              min-width: rem-calc(25);
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .jks-logo-wrapper {
        display: flex;
        justify-content: center;
        margin-top: rem-calc(64);
        img {
          max-width: 200px;
        }
      }
    }
  }
   */
  .info {
    &,
    span,
    strong,
    a,
    p {
      @include body(xs);
    }

    .label {
      margin-right: rem-calc(4);
      font-weight: 700;
    }
  }
  .social-icons-wrapper {
    margin-top: rem-calc(10);
  }
  .text {
    margin-top: rem-calc(10);
  }
  * {
    @include body(s);
  }
  strong {
    font-weight: 700 !important;
  }

  .newsletter-register-link {
    @include media-breakpoint-up(lg) {
      margin-bottom: calc(#{$whitespace} * 2);
    }
    > div {
      row-gap: rem-calc(10);
    }
    * {
      @include headline(m);
    }
    .newsletter-text {
      margin-right: rem-calc(8);
    }
    .newsletter-button {
      padding-top: rem-calc(8);
    }
  }
}

.partner-footer {
  background: $body-bg;
  padding-top: calc(#{$whitespace} / 2);
  padding-bottom: calc(#{$whitespace} / 2);
  @include media-breakpoint-up(lg) {
    padding-top: $whitespace;
    padding-bottom: $whitespace;
  }
  img {
    max-width: rem-calc(300);
  }
  * {
    @include body(xs);
  }
  .logo-wrapper {
    margin-top: $whitespace;
    display: flex;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}
