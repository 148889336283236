#pager-full-page {
  padding: $paragraph-section-padding-mobile;
  @include media-breakpoint-up(md) {
    padding: $paragraph-section-padding;
  }
  a {
    @include media-breakpoint-up(md) {
      white-space: nowrap;
    }
  }
  .previous {
    span {
      color: black;
      padding-left: rem-calc(15);
    }
  }
  .next {
    span {
      color: black;
      padding-right: rem-calc(15);
    }
  }
  @include media-breakpoint-down(sm) {
    .previous {
      padding-bottom: rem-calc(15);
    }
    .next {
      padding-top: rem-calc(15);
    }
  }
}
