body.fullscreen-menu-open {
  //overflow-y: hidden !important;
  //max-height: 100vh;
}

#fullscreen-menu {
  background: $green-1;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: -150%;
  z-index: 10;
  transition: all 500ms ease;
  overflow-y: auto;
  height: 100%;
  &.active {
    left: 0;
  }
  display: flex;
  flex-flow: column;
  @include media-breakpoint-up(lg) {
    justify-content: space-between;
  }
  .main-menu-desktop {
    display: flex !important;
  }
  .nav-wrap {
    display: flex;
    flex-flow: column;
    padding-top: rem-calc(100);
    @include media-breakpoint-up(lg) {
      padding-top: rem-calc(140);
    }
    nav.header-navigation {
      margin-bottom: rem-calc(32);
      ul {
        li {
          a {
            @include arrow-link(l);
            @include hover-letter-spacing(l);
          }
        }
      }
    }
  }
  .main-menu-desktop {
    margin-bottom: rem-calc(25);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(50);
    }
    .topic-headline {
      @include body(xs);
      margin-bottom: rem-calc(10);
      display: inline-block;
      pointer-events: none;
    }
    ul {
      display: flex;
      flex-flow: column;
      ul {
        margin-bottom: rem-calc(30) !important;
      }
      li {
        margin-bottom: rem-calc(4);
        a {
          color: $dark;
        }
      }
    }
  }
  .menu-footer {
    .footer-navigation {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        flex-flow: wrap;
      }
      ul {
        display: inline-flex;
        flex-flow: wrap;
        li {
          margin-right: rem-calc(12);
          a {
            color: $dark;
          }
        }
      }
    }
  }
}
