/* Accordeon related styles set in _paragraph-akkordion-text */
section.paragraph.paragraph-extended-teaser-overview.type-person {
  article:not(:first-child) {
    margin-top: rem-calc(45);
  }

  .description.text * {
     &:not(h1),
     &:not(h2),
     &:not(h3),
     &:not(h4) {
       @include body(s);
     }
  }
}