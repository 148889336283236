.paragraph-media {
  video {
    width: 100%;
  }
  .local-video {
    position: relative;
    .playpause {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      cursor: pointer;
      background: $white-two;
      border-radius: 50%;

      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      transition-delay: 0s;

      width: rem-calc(50);
      height: rem-calc(50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.video-playing {
    .local-video {
      .playpause {
        opacity: 0;
        transition-delay: 0.5s;
      }
      &:hover {
        .playpause {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
}

.iframe-wrap {
  position: relative;
  &.iframe-16-9 {
    padding: 56.25% 0 0 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.paragraph-bilder-slider {
  .masonry-wrapper {
    > .container {
      padding: 0;
    }
    .media-image {
      margin-bottom: $grid-gutter-width;
    }
  }
  .button-wrapper {
    justify-content: flex-end;
    display: flex;
    flex-flow: column;
    margin-bottom: $grid-gutter-width;
  }


  .custom-slider-component {

    .caption {
      display: block;
      @include body(m);
    }

    .infos {
      padding-left: 0;
    }

    .media-image {
      img {
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }

      &.portrait {
        img {
          object-fit: contain;
        }
      }
    }

    .slick-arrow {
      color: black;
      width: auto;
      height: auto;
      @include headline(xl);
      font-family: "area-inktrap", sans-serif;
      font-weight: 700;
      transition: 250ms all ease-in-out;

      &.slick-next {
        right: $grid-gutter-width;
      }

      &.slick-prev {
        left: $grid-gutter-width;
      }

      &:hover {
        &.slick-next {
          right: calc(#{$grid-gutter-width} /2);
        }

        &.slick-prev {
          transform: calc(#{$grid-gutter-width} /2);
        }
      }
    }

    .counter {
      order: 1;
    }
  }
}
