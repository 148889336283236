section.paragraph.paragraph-download {
  padding: calc(#{$whitespace} / 2) 0;
  h3 {
    @include body(m);
    margin-bottom: 0;
  }
  button {
    align-self: baseline;
    padding: rem-calc(0) rem-calc(4) rem-calc(3) rem-calc(4);
    border-bottom: 2px solid;
    min-width: unset;
    color: $black;
    font-size: 150%;
    transition: 250ms transform ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
  .inktrap.icon {
    @include body(m);
  }
  a {
    &:hover {
      text-decoration: none;
      button {
        transform: scale(1.2);
      }
      * {
        text-decoration: none;
      }
    }
  }
  .download-icon {
    max-width: initial;
    width: auto;
    flex-grow: 0;
  }
  .title-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.module-wrapper.download + .module-wrapper.download.same-as-before {
  .paragraph-download {
    padding-top: 0;
  }
}
