section.paragraph-teaser-list {
  padding-bottom: calc(#{$whitespace} / 2);
  h2 {
    margin-bottom: rem-calc(10);
    @include headline(xl);
  }
  .more {
    margin-top: rem-calc(30);
    @include media-breakpoint-up(md) {
      margin-top: rem-calc(60);
    }
  }
}

section.paragraph-teaser-list.paragraph-teaser-list-slick {
  .arrow-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    .carousel-arrow {
      max-width: 45%;
      background: transparent;
      border: none;
      font-size: rem-calc(40);
      @include media-breakpoint-up(lg) {
        @include headline(xl);
      }
      color: $black !important;
    }
    .carousel-arrow + .carousel-arrow {
      margin-left: 10px;
    }
  }
  .container.title {
    position: relative;
    margin-bottom: calc(#{$whitespace} / 3);
    @include media-breakpoint-up(lg) {
      margin-bottom: calc(#{$whitespace} / 2);
    }
    h2 {
      margin-top: rem-calc(10);
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: rem-calc(16);
      }
    }
    &:before {
      content: "";
      width: calc(100% - 32px);
      left: 16px;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 30px);
        left: 20px;
      }
      position: absolute;
      bottom: 0;
      height: 1px;
      background: $dark;
    }
  }
  /* the slides */
  .slick-slide {
    cursor: pointer;
    > div {
      padding: 0 $grid-gutter-width/2 0 $grid-gutter-width/2;
      @include media-breakpoint-up(lg) {
        padding: 0 0 0 $grid-gutter-width/2;
      }
    }
    &:first-child {
      > div {
        //padding-left: rem-calc(30);
      }
    }
  }
  /* the parent */
  .slick-track, .slick-list {
    @include media-breakpoint-up(lg) {
      height: 60vh;
    }
  }
  .node-teaser {
    @include media-breakpoint-up(lg) {
      height: 60vh;
      width: auto;
    }
    img {
      width: auto;
      height: 100%;
      margin-bottom: rem-calc(15);
    }
  }
}

.paragraph-teaser-list-veranstaltung {
  article.node {
    margin-bottom: 2rem;
  }
}