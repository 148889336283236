section.paragraph.paragraph-logos-partners {
  .heading {
    margin-bottom: rem-calc(65);
    @include body(m);
  }
  .partner {
    margin-bottom: rem-calc(65);
    img {
      width: auto;
      margin: 0 auto;
      display: block;
    }
  }
}
