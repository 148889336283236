.paragraph-audio {
  h2 {
    margin-bottom: rem-calc(40);
  }

  .audio-section {
    .time {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem-calc(10);
    }
    .progress-bar {
      margin-bottom: rem-calc(10);
      .bar {
        width: 100%;
        height: rem-calc(6);
        background-color: $white-two;
        .fill {
          height: rem-calc(6);
          z-index: 1;
          background-color: $primary-color-active;
          transition: width 1s;
        }
      }
    }
    .button {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem-calc(25);
      button {
        appearance: none;
        background-color: transparent;
        border: none;
        box-shadow: none;
        &:active {
          outline: none;
        }
        &:focus,
        &:focus-visible {
          outline: rem-calc(1) solid;
        }
      }
    }
  }
}
