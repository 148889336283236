@font-face {
  font-family: "area-inktrap";
  src: url("../fonts/Area-Inktrap-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "area-inktrap";
  src: url("../fonts/Area-Inktrap-Black.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "area-normal";
  src: url("../fonts/Area-Normal-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "area-normal";
  src: url("../fonts/AreaNormal-Black.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

strong, strong * {
  font-weight: 700 !important;
}

@mixin body($type) {
  font-family: "area-normal", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
  font-weight: 600;
  @if $type == xs {
    //font-size: rem-calc(10);
    font-size: rem-calc(12);
    line-height: 1.2;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(14);
    }
  }
  @if $type == s {
    //font-size: rem-calc(10);
    font-size: rem-calc(12);
    line-height: 1.2;
    @include media-breakpoint-up(sm) {
      font-size: rem-calc(14);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(20);
    }
  }
  @if $type == m {
    font-size: rem-calc(16);
    line-height: 1.25;
    @include media-breakpoint-up(md) {
      font-size: rem-calc(20);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24);
    }
  }
  @if $type == l {
    font-size: rem-calc(24);
    line-height: 1.375;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(36);
    }
  }
  @if $type == xl {
    font-size: rem-calc(36);
    line-height: 1.375;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(48);
    }
  }
}

@mixin headline($type) {
  font-family: "area-normal", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
  @if $type == m {
    font-size: rem-calc(18);
    line-height: 1.2;
    @include media-breakpoint-up(md) {
      font-size: rem-calc(20);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24);
    }
  }
  @if $type == l {
    font-size: rem-calc(20);
    line-height: 1.2;
    @include media-breakpoint-up(sm) {
      font-size: rem-calc(24);
    }
    @include media-breakpoint-up(md) {
      font-size: rem-calc(32);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(48);
    }
  }
  @if $type == xl {
    font-size: rem-calc(24);
    line-height: 1.2;
    @include media-breakpoint-up(sm) {
      font-size: rem-calc(32);
    }
    @include media-breakpoint-up(md) {
      font-size: rem-calc(48);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(80);
    }
  }
}

.inktrap {
  font-family: "area-inktrap", sans-serif !important;
  font-weight: 700 !important;
  font-size: rem-calc(24);
}
