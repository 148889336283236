section.paragraph-intromodul {
  padding: 0;
  overflow: hidden;
  .container {
    max-width: 100%;
  }
  .animation-col {
    background: $green-2;
    min-height: calc(85vh - 60px);
    overflow: hidden;
    position: relative;
    .cutout {
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
      height: auto;
      object-fit: contain;
      &:nth-child(1) {
        top: 15%;
        left: 40%;
        animation: shake-2 18s;
        animation-direction: alternate-reverse;
        animation-iteration-count: infinite;
      }
      &:nth-child(2) {
        top: -2.5%;
        left: -5%;
        animation: shake-2 24s;
        animation-direction: alternate-reverse;
        animation-iteration-count: infinite;
      }
      &:nth-child(3) {
        width: 40%;
        @include media-breakpoint-up(lg) {
          width: 20%;
        }
        top: 5%;
        left: 20%;
        animation: shake-3 22s;
        animation-direction: alternate-reverse;
        animation-iteration-count: infinite;
      }
      &:nth-child(4) {
        top: unset;
        bottom: 4%;
        left: 5%;
        width: 45%;
        @include media-breakpoint-up(lg) {
          width: 35%;
        }
        animation: shake-1 26s;
        animation-direction: normal;
        animation-iteration-count: infinite;
      }
      &:nth-child(5) {
        top: unset;
        bottom: 15%;
        left: 30%;
        width: 50%;
        @include media-breakpoint-up(lg) {
          width: 40%;
        }
        animation: shake-1 28s;
        animation-direction: normal;
        animation-iteration-count: infinite;
      }
      &:nth-child(6) {
        bottom: 5%;
        right: 5%;
        top: unset;
        left: unset;
        width: 40%;
        @include media-breakpoint-up(lg) {
          width: 28%;
        }
        animation: shake-1 16s;
        animation-direction: normal;
        animation-iteration-count: infinite;
      }
      &.letter {
        width: 20%;
        &.j {
          left: 3%;
          top: 3%;
        }
        &.k {
          left: 25%;
          top: 25%;
        }
        &.s {
          left: 55%;
          top: 10%;
        }
        &.dresden {
          left: unset;
          top: unset;
          right: 5%;
          bottom: 4%;
          width: 70%;
        }
      }
    }
  }
  .link-col {
    background: $yellow;
    @include media-breakpoint-up(lg) {
      padding-left: rem-calc(30);
      padding-right: rem-calc(30);
    }
    ul {
      li {
        padding: rem-calc(22) 0 rem-calc(20) 0;
        border-bottom: rem-calc(2) solid;
        justify-content: space-between;
        display: flex;
        flex-flow: wrap;
        align-items: baseline;
        &:last-child {
          border-bottom: 0;
        }
        span.inktrap {
          transition: 250ms all;
          position: absolute;
          right: rem-calc(30);
        }
        a {
          @include hover-letter-spacing(l);
          width: 100%;
        }
        &:hover {
          span.inktrap {
            transform: rotate(59deg);
            margin-left: rem-calc(-30);
          }
        }
        a, span {
          @include headline(l);
          line-height: 1 !important;
          color: inherit;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}