.node-teaser.teaser-kurs {
  padding: 0;

  .accordion {
    border-bottom: none;
  }

  .accordion__button[aria-expanded="true"] {
    .teaser-kurs-collapse .arrow {
      transform: rotate(180deg);
    }
  }

  .accordion__button {
    .teaser-kurs-collapse {
      text-align: right;
      .arrow {
        display: inline-block;
        width: auto;
        transition: 250ms transform;
      }
    }
  }

  .list-teaser {
  }

  &:nth-child(even) {
    background-color: $body-bg;
  }

  &:nth-child(odd) {
    background-color: $lilac;
  }

  .list-teaser {
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        @include body(xs);
        white-space: nowrap;
        display: block;
        margin-right: 0.5rem;
      }
    }

    .teaser-kurs-date {
      .time-element {
        &:first-child {
          @include media-breakpoint-up(md) {
            //margin-right: .5rem;
          }
        }
      }
    }

    .teaser-kurs-time {
      > * {
        margin-bottom: rem-calc(1);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .top {
      font-size: 0.9rem;
    }

    .teaser-kurs-title h2, .age-recommendation, .teaser-kurs-time .times, .teaser-kurs-date{
        @include body(m);
    }

    .teaser-kurs-title {
      margin-bottom: rem-calc(8);
    }

    .subscribe {
      @include body(xs);
      a {
        text-decoration: underline;
      }
    }

    .open-collapse {
      transform: rotate(0);
      font-size: rem-calc(24);
      transition: 0.3s transform;

      &.open,
      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  .extendend-content {
    margin-top: rem-calc(15);
    .informations {
      margin-top: rem-calc(15);
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
      @include body(xs);
      > div {
        padding-bottom: 10px;
        a {
          text-decoration: underline;
        }
      }
    }

    .next-events {
      strong {
        margin-right: 1rem;
        text-decoration: underline;
        cursor: pointer;
      }

      img {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
      }
    }

    .tickets {
      .price {
        display: flex;
        justify-content: center;
        flex-direction: column;

        small {
          @include body(xs);
        }
      }

      .buy-tickets {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        align-items: center;

        .number {
          @include body(m);
          padding-left: 1rem;
          padding-right: 1rem;
          flex-basis: 90px;
          text-align: center;
        }

        img {
          margin-bottom: 0;
        }
      }
      .submit-tickets {
        padding-top: 2rem;
        a {
          text-decoration: none;
        }
      }
    }
    .image {
      order: -1;
      @include media-breakpoint-up(md) {
        order: unset;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .teaser-kurs-time {
      order: 5;
    }
  }
  @include media-breakpoint-down(sm) {
    .list-teaser {
      > div {
        display: flex;
        flex-flow: column;
        margin-bottom: rem-calc(8);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .teaser-kurs-date {
      order: 0;

      .time-element {
        display: inline;

      }
    }

    .teaser-kurs-title {
      order: 1;
    }

    .age-recommendation {
      order: 4;
    }

    .text {
      padding-top: rem-calc(4);
    }

    .teaser-kurs-time {
      order: 3;
      margin-top: rem-calc(2);
    }

    .teaser-kurs-collapse {
      order: 2;
      button {
        width: 100%;
        height: 100%;
      }
    }
  }
}
