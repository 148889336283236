@mixin header-link {
  @include body(m);
}

header#site-header {
  position: relative;
  z-index: 100;
  .fixed-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    padding: rem-calc(11) 0;
    display: flex;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: white;
    height: rem-calc(60);
    > .container {
      > .row {
        justify-content: space-between;
      }
    }
  }
  .site-logo-button {
    z-index: 20;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-left: rem-calc(12);
    @include media-breakpoint-up(md) {
      padding-left: rem-calc(16);
    }
    padding-right: rem-calc(32);
    img {
      width: rem-calc(94);
    }
  }
  nav.main-menu-desktop {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
    flex-flow: column;
    justify-content: center;
    ul {
      margin-bottom: 0 !important;
      //margin-top: rem-calc(4);
      display: flex;
      li {
        a,
        span {
          cursor: pointer;
          @include header-link();
          margin-right: rem-calc(25);
          color: inherit;
        }
      }
    }
  }
  #toggle-fullscreen-menu {
    display: inline-block;
    cursor: pointer;
    z-index: 20;
    margin-right: 16px;
    margin-top: 6px;
    height: 34px;

    > div {
      width: rem-calc(35);
      height: rem-calc(2);
      background-color: #333;
      margin: rem-calc(4) 0;
      transition: 0.4s;
    }
    &:hover:not(.active) {
      > div:not(:first-child) {
        margin: rem-calc(6) 0;
      }
      > div:first-child {
        margin-top: rem-calc(2);
      }
    }
    &.active {
      > div {
        margin: rem-calc(4) 0;
      }
      .bar1 {
        transform: rotate(-45deg) translate(rem-calc(-5), rem-calc(5));
      }
      .bar2,
      .bar3 {
        opacity: 0;
      }
      .bar4 {
        transform: rotate(45deg) translate(rem-calc(-8), rem-calc(-8));
      }
    }
  }

  nav.footer-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    ul {
      margin-bottom: 0;
    }

    .social-icons-wrapper {
      margin-top: rem-calc(8);
      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
        margin-top: rem-calc(-3);
      }
    }
  }
}
