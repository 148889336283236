// Bürgerhaus Custom Styling
.main-content[data-special-design="bürgerhaus"], article.node-landingpage[data-special-design="bürgerhaus"] {
  .main-page-content, .content-wrap {
    color: $dark-blue;
  }

  /*#pageFooter {
    background: $dark-blue;
    color: white;
    * {
      color: white;
    }

    .newsletter-button {
      border-color: white;
    }

    .jks-logo-wrapper, svg {
      filter: invert(1);
    }
  }*/
}

.module-wrapper[data-has-dark-color="true"] {
  color: $light-grey;
}