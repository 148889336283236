section.paragraph.paragraph-click-suggestions {
  padding: 0;
  ul {
    margin: 0;
    li {
      padding: rem-calc(22) 0 rem-calc(26) 0;
      border-bottom: rem-calc(2) solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        //border-bottom: 0;
      }
      svg {
        width: rem-calc(25);
        margin-right: rem-calc(5);
        min-width: rem-calc(25);
      }
      a, span {
        color: $black;
        @include headline(xl);
        line-height: 1;
        text-decoration: none;
        @include hover-letter-spacing(xl);
        &:hover {
          text-decoration: none;
        }
      }
      a {
        width: 100%;
      }
    }
  }
}
