/**
 * Teaser Views modes.
 */
article.node-teaser.teaser-veranstaltung {
  @include media-breakpoint-down(lg) {
    padding-bottom: rem-calc(40);
  }

  .mobile-header {
    margin-bottom: rem-calc(15);

    span {
      display: block;
    }

    div.d-flex {
      text-align: right;
      flex-flow: column;
    }
  }
  .meta-date {
    span {
      padding-right: rem-calc(20);
    }
  }

  .text {
    @include body(xs);

    p {
      display: inline;
    }

    a {
      padding-left: rem-calc(10);
      color: $link-color;
      text-decoration: underline;
    }
  }

  .date {
    margin-bottom: 0.5rem;
  }

  .btn {
    min-width: rem-calc(120);
    margin-right: rem-calc(10);
  }

  &.teaser-doppel-einzel {
    .buttons {
      a.btn {
        margin-top: rem-calc(15);

        &:first-child {
          margin-top: rem-calc(30);
        }
      }
    }
  }

  &.teaser-list {
    .meta-date.top-line {
      display: flex;
      margin-bottom: 0;

      > * {
        padding-right: rem-calc(7);
      }
    }
  }

  &.node-teaser-default,
  &.teaser-doppel-einzel {
    .top-line {
      @include body(xs);
    }

    .location {
      @include body(xs);
    }
  }
}

.paragraph-extended-teaser-overview.type-veranstaltung {
  article.node-teaser.teaser-veranstaltung {
    > .row {
      width: 100%;
    }
  }
}

.paragraph-teaser-list {
  .node-teaser.teaser-veranstaltung {
    display: block;
  }
}
